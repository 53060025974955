<template>
  <div class="with-anchors">
    <h1 class="display-l">White Papers</h1>


    <section>
        <p>We use white papers to promote or highlight the features of a solution, product, or service.</p>
        <p>An InDesign(.indd) template has been prepared, including several model pages to help illustrate the content. </p>
    &nbsp;
    <p><img src="../../assets/img/white-papers.png" alt="" role="presentation" /></p>
        
    </section>

    <DownloadCTA title="Download White Paper template " 
                description="Files available in .indd format" 
                buttonTitle="Download .zip"
                link="/zip/Intelematics-templates_Whitepapers Folder.zip" />

  </div>
</template>

<script>
import DownloadCTA from "../common/DownloadCTA" 

export default {
  name: "WhitePapers",
    components: {
    DownloadCTA
  },
};
</script>
